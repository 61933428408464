import { WASMWorkletProcessor } from '../../audioUtils';
// @ts-ignore
import Module from './Processor.wasmmodule.js';

registerProcessor(
  'ReverbProcessor',
  class ReverbProcessor extends WASMWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'diffusion',
          defaultValue: 0.625,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'lp',
          defaultValue: 0.7,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reverbTime',
          defaultValue: 0.5,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'amount',
          defaultValue: 0.5,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'gain',
          defaultValue: 0.5,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    constructor() {
      super(
        Module,
        (
          processor,
          inputPointer,
          outputPointer,
          {
            diffusion: [diffusion],
            lp: [lp],
            reverbTime: [reverbTime],
            amount: [amount],
            gain: [gain],
          },
          channelCount,
        ) => {
          processor.process(
            inputPointer,
            outputPointer,
            channelCount,
            diffusion,
            lp,
            reverbTime,
            amount,
            gain,
          );
        },
      );
    }
  },
);
